import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import { dropdownCategory, dropdownAction } from "../../utils/GoogleAnalytics/events/categories";
import "../../styles.css"
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useSelector } from 'react-redux';
import useFetchIndustry from "../../api/FetchIndustry";
import "../../styles.css"

const { Option } = Select;

const IndustrySelector = ( { question, selectedOptionsFromParent, onSelect, onNext, onBack, isPagination, isQuestionNavigation, isValidation } ) => {
    const [selected, setSelected] = useState( selectedOptionsFromParent || [] );
    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const clientConfig = useSelector( state => state.clientConfiguration );

    const { response: industryData, isLoading, FetchIndustry } = useFetchIndustry();

    const numericalDigits = question?.description.match( /\d+/g );
    const isDescriptionIncludesDigit = /\d/.test( question?.description );

    useEffect( () => {
        if ( !industryData ) {
            FetchIndustry();
        }
    }, [] );

    useEffect( () => {
        if ( String( selectedOptionsFromParent?.length ) === numericalDigits[0] ) {
            setIsOptionSelected( true );
            isValidation( true );
        } else {
            setIsOptionSelected( false );
        }
    }, [selectedOptionsFromParent] );

    const saveToLocalStorage = ( questionId, selectedOptions ) => {
        let industries = JSON.parse( localStorage.getItem( 'industries' ) ) || {};
        industries[questionId] = selectedOptions;
        localStorage.setItem( 'industries', JSON.stringify( industries ) );
    };

    const handleSelect = ( selectedOptions ) => {
        if ( isDescriptionIncludesDigit && selectedOptions.length > numericalDigits ) {
            return;
        }
        onSelect( selectedOptions );
        setSelected( selectedOptions );

        if ( selectedOptions.length === numericalDigits[0] ) {
            setIsOptionSelected( true );
            isValidation( true );
        } else {
            setIsOptionSelected( false );
        }

        saveToLocalStorage( question.id, selectedOptions );
        TrackGoogleAnalyticsEvent( dropdownCategory, dropdownAction );
    };

    useEffect( () => {
        setSelected( selectedOptionsFromParent || [] );
    }, [selectedOptionsFromParent] );

    const { client_name } = useParams();

    const perQuestionTimer = dynamicData.default.perQuestionTimer;

    const handleKeyDown = ( e ) => {
        if ( e.key === "Enter" ) {
            onNext();
        }
    };

    return (
        <div className={ `flex flex-col justify-between ${ isPagination ? "mb-4" : "mt-2 lg:h-full" }` }>
            <div className="flex flex-col">
                <QuestionAndDescription question={ question } />
                <Select
                    className="w-full dynamic-dropdown"
                    value={ Array.isArray( selected ) ? selected : [] }
                    onChange={ handleSelect }
                    showSearch={ true }
                    mode="multiple"
                    placeholder={ question?.placeholder_text }
                    filterOption={ ( input, option ) =>
                        option.children.toLowerCase().indexOf( input.toLowerCase() ) >= 0
                    }
                    onKeyDown={ handleKeyDown }
                    tabIndex={ 0 }
                >
                    { industryData?.map( ( industry, index ) => (
                        <Option key={ index } value={ industry }>
                            { industry }
                        </Option>
                    ) ) }
                </Select>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    isQuestionNavigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                            { question?.question_optional === "optional" && (
                                <button className="skip-button" onClick={ onNext }>
                                    Skip
                                </button>
                            ) }
                            {
                                perQuestionTimer ?
                                    <></> :
                                    <button className="back-button" onClick={ onBack }>
                                        Back
                                    </button>
                            }
                            <button
                                className="next-button"
                                onClick={ isOptionSelected ? onNext : null }
                                style={ {
                                    backgroundColor: isOptionSelected ? `${ clientConfig.primary_color }` : `${ clientConfig.primary_color }80`,
                                    cursor: isOptionSelected ? 'pointer' : 'not-allowed'
                                } }
                            >
                                Next
                            </button>
                        </div>
            }
        </div>
    );
};

export default IndustrySelector;
