import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useFetchFormConfig from '../../api/FetchFormConfig';
import { setFormConfiguration } from '../../redux/student/studentSlice';
import { dynamicData } from '../../helpers/data';
import './instructions.css';

const Instructions = ( { customProceed, handleProceed } ) => {
    const [isYoutubeLink, setIsYoutubeLink] = useState( false );
    const [link, setLink] = useState( null );
    const [text, setText] = useState();
    const [isChecked, setIsChecked] = useState( false );

    const handleCheckboxChange = ( event ) => {
        setIsChecked( event.target.checked );
    };

    const playerRef = useRef( null );

    const { response: formConfigData, isLoading: formConfigLoading, fetchFormConfig } = useFetchFormConfig();
    const formConfiguration = sessionStorage.getItem( 'formConfiguration' );

    const { client_name, grade, user_type, test_type, testId } = useParams();
    const clientConfig = useSelector( state => state.clientConfiguration );
    const formConfig = useSelector( state => state.formConfiguration );
    const isClientActive = clientConfig?.is_active;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const playerWidth = 480;
    const playerHeight = 270;

    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const user_email = query.get( 'user_email' );
    const user_name = query.get( 'user_name' );
    const user_id = query.get( 'user_id' );

    useEffect( () => {
        if ( client_name && !formConfiguration && isClientActive ) {
            fetchFormConfig( client_name, test_type, testId );
        }
    }, [client_name, isClientActive] )

    useEffect( () => {
        if ( formConfigData && formConfigData?.data?.form_config ) {
            dispatch( setFormConfiguration( formConfigData?.data ) );
            sessionStorage.setItem( 'formConfiguration', JSON.stringify( formConfigData?.data ) );

            const timeout = setTimeout( () => {
                sessionStorage.removeItem( 'formConfiguration' );
                fetchFormConfig( client_name, test_type, testId );
            }, 60 * 60 * 1000 );
            return () => clearTimeout( timeout );
        }
    }, [formConfigData, formConfigLoading] )

    useEffect( () => {
        if ( formConfig && formConfig?.form_config?.instructions ) {
            const youtubeRegex = /https?:\/\/(www\.)?(youtube\.com|youtu\.be)\/[^\s]+/;
            if ( youtubeRegex.test( formConfig?.form_config?.instructions ) ) {
                setIsYoutubeLink( true );
            }
            const match = formConfig?.form_config?.instructions.match( youtubeRegex );
            if ( match ) {
                setLink( match[0] );
            }
            const newText = formConfig?.form_config?.instructions.replace( youtubeRegex, '' );
            setText( newText );
        }
    }, [formConfig] );

    const handleProceedToTest = () => {
        if ( isChecked ) {
            navigate( `/${ client_name }/test/${ testId }/?user_email=${ user_email }&user_name=${ user_name }&user_id=${ user_id }`, { replace: true } )
        }
    };

    return (
        <div className="w-full max-w-screen flex flex-col font-poppins gap-[1rem] px-[2rem] sm:px-0 pb-[2rem]">
            <div className="flex justify-center items-center w-full h-24">
                <div
                    className="flex justify-center items-center lg:h-[150px] sm:h-[50px] lg:w-[250px] sm:w-[100px] w-1/2"
                    style={ { maxWidth: "100%", maxHeight: "100%" } }
                >
                    <img
                        src={ clientConfig?.logo || formConfigData?.logo_link || dynamicData.default.default_left_section.logo_link || "" }
                        alt="logo"
                        className="max-h-full max-w-full"
                    />
                </div>

                {/*<div className='w-2/3 flex'>
                    <p className='text-2xl font-normal'>
                        { formConfig?.form_data?.name }
                    </p>
                </div>*/}
            </div>
            <div className='w-full flex justify-center items-center'>
                <p className='text-lg font-semibold'>
                    Please read the instructions carefully
                </p>
            </div>

            {
                isYoutubeLink &&
                <div className='w-full flex flex-col gap-[1rem] px-[4rem] mt-[1rem]'>
                    <p className='text-xl font-semibold underline'>
                        Video Instructions
                    </p>

                    <div className='flex justify-center'>
                        <div className='rounded-xl border shadow-lg'>
                            <div className='p-3'>
                                <ReactPlayer
                                    ref={ playerRef }
                                    url={ link }
                                    playing
                                    controls
                                    width={ playerWidth }
                                    height={ playerHeight }
                                />
                            </div>
                        </div>
                    </div>

                </div>
            }

            <div className='w-full flex flex-col gap-[1rem] mt-[1rem]'>
                <p className='text-xl font-semibold underline'>
                    General Instructions
                </p>

                <div
                    className='font-poppins text-base custom-list'
                    dangerouslySetInnerHTML={ { __html: text } }
                />

            </div>

            <div className="w-full flex lg:flex-row sm:flex-col sm:gap-6 justify-between bg-gray-200 rounded-xl p-4">
                <div className='flex lg:items-center sm:items-start'>
                    <input
                        type="checkbox"
                        className="form-checkbox lg:h-5 lg:w-5 sm:w-8 sm:h-8 text-gray-600"
                        checked={ isChecked }
                        onChange={ handleCheckboxChange }
                    />
                    <p className="text-black font-semibold lg:text-lg lg:ml-2 sm:text-sm sm:ml-4">
                        I have read all the above instructions and ready to take the test
                    </p>
                </div>

                <button
                    className="text-white font-semibold py-3 px-4 rounded"
                    style={ {
                        backgroundColor: isChecked ? clientConfig.primary_color : `${ clientConfig.primary_color }80`,
                        cursor: isChecked ? 'pointer' : 'not-allowed'
                    } }
                    onClick={ isChecked && customProceed ? handleProceed : handleProceedToTest }

                >
                    Proceed to Test
                </button>
            </div>
            {/*<div className="row row-height">
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 p-[4rem]" id="start">
                    <pre>
                        <p className='font-poppins text-xl font-bold mb-4'>
                            Instructions
                        </p>
                        <div
                            className='font-poppins text-base font-normal'
                            dangerouslySetInnerHTML={ { __html: formConfig?.form_config?.instructions } }
                        />
                    </pre>
                </div>
            </div>*/}
        </div>
    );
};

export default Instructions 