import React, { useState, useEffect } from "react";
import { Input, notification, Spin } from "antd";
import {
    EditTwoTone,
    CheckCircleOutlined,
} from '@ant-design/icons';
import useVerifyOTP from "../../api/useVerifyOtp";
import useRequestOTP from "../../api/useRequestOtp";
import { setIsFormSubmitted } from "../../redux/student/studentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import useUpdateVerifyStudents from "../../api/useUpdateVerifyStudent";
import CustomPhoneInput from "../../helpers/CustomPhoneInput";
import { isValidPhoneNumber } from 'react-phone-number-input';
import '../DataTables.css';

const MobileVerification = ( {
    onAnswerChange, phoneNumber, onNext, currentComponent
} ) => {
    const [isMobileValid, setIsMobileValid] = useState( true );
    const [isOTPBoxVisible, setIsOTPBoxVisible] = useState( false );
    const [isEditable, setIsEditable] = useState( false );
    const [mobileNumber, setMobileNumber] = useState( "" );
    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [isCheckIcon, setIsCheckIcon] = useState( false );

    const [isSendOTPClickable, setIsSendOTPClickable] = useState( true );
    const [isVerifyOTPClickable, setIsVerifyOTPClickable] = useState( false );
    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clientConfig = useSelector( state => state.clientConfiguration );

    const { response: updatedResponse, isLoading: updatingStatus, updateVerifyStudents } = useUpdateVerifyStudents();

    const studentRollNumber = localStorage.getItem( 'student_roll_number' );

    const { client_name, grade } = useParams();
    const clientId = localStorage.getItem( 'clientId' )
    const userPhoneNumber = localStorage.getItem( 'phone_number' ) || '';

    useEffect( () => {
        setMobileNumber( phoneNumber || userPhoneNumber );
        setIsMobileValid( /^\d{10}$/.test( phoneNumber ) );
    }, [phoneNumber] );

    const { requestOTP } = useRequestOTP();
    const { data, isLoading, error, verifyOTP } = useVerifyOTP();

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                updateVerifyStudents( studentRollNumber, false, true )
            }
        }
    }, [data] );

    useEffect( () => {
        if ( updatedResponse ) {
            notification.success( {
                message: 'OTP Verified',
                description: 'Your OTP has been successfully verified.'
            } );
            currentComponent();
            //if ( client_name === "jc" ) {
            //    navigate( `/${ client_name }/test/${ grade }/thank_you` )
            //}
            //dispatch( setIsFormSubmitted( true ) );
        }
    }, [updatedResponse] )

    const handleEditNumber = () => {
        setIsEditable( true );
        setIsCheckIcon( true );
        setIsSendOTPClickable( false );
    };

    const handleCheckNumber = () => {
        if ( isMobileValid ) {
            setIsEditable( false );
            setIsCheckIcon( false );
            setIsSendOTPClickable( true );
        }
    };

    const handleSendOTP = async () => {
        onNext();
        setIsOTPBoxVisible( true );
        setIsSendOTPClickable( false );
        setIsVerifyOTPClickable( true );
        requestOTP( mobileNumber, clientId );
    };

    const handleMobileNumberChange = ( value ) => {
        onAnswerChange( value );
        setMobileNumber( value );
        setIsMobileValid( /^\d{10}$/.test( value ) );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    const handleVerificationSubmit = async () => {
        const otp = otpValues.join( "" );
        verifyOTP( mobileNumber, otp );
    };

    useEffect( () => {
        if ( mobileNumber && !isValidPhoneNumber( mobileNumber ) ) {
            setIsMobileValid( false )
        } else if ( mobileNumber && isValidPhoneNumber( mobileNumber ) ) {
            setIsMobileValid( true )
        }
    }, [mobileNumber] )
    return (
        <>
            {
                updatingStatus &&
                <div className="overlay">
                    <Spin size="large" />
                </div>
            }
            <div className="flex flex-col mt-2 justify-between h-full">
                <div className="flex flex-col">
                    <h1 className="font-poppins text-2xl font-medium bg-white mb-2 select-none">
                        Verify your Phone Number
                    </h1>
                    <h1 className="font-poppins text-sm font-normal text-gray-700 mb-4 select-none">
                        You are just one step away! Verify your OTP to access your results.
                    </h1>

                    <div className="flex flex-row align-center items-center">
                        <CustomPhoneInput
                            value={ mobileNumber }
                            onChange={ ( e ) => handleMobileNumberChange( e ) }
                            international={ true }
                            countryCallingCodeEditable={ false }
                            withCountryCallingCode={ true }
                        />
                    </div>

                    { isOTPBoxVisible && (
                        <>
                            <div className="flex lg:gap-[2rem] sm:gap-2 lg:w-1/2 sm:w-full text-center items-center sm:mt-2 lg:mt-6">
                                { otpValues.map( ( value, index ) => (
                                    <Input
                                        key={ index }
                                        id={ `otp-input-${ index }` }
                                        className="h-[3rem] text-center text-xl border-2"
                                        value={ value }
                                        maxLength={ 1 }
                                        onChange={ ( e ) => handleOTPChange( e, index ) }
                                    />
                                ) ) }
                            </div>
                            <p className="font-poppins text-sm hover:text-red-500 font-medium mt-2">
                                Please enter the OTP sent to your mobile number
                            </p>
                        </>
                    ) }
                </div>
                <div className="flex flex-row gap-2 justify-end mb-8 mt-[3rem]">
                    <>
                        {/*<button
                            className={ `px-4 py-2 rounded-md text-white ${ isMobileValid && isSendOTPClickable ? "bg-blue-500" : "bg-blue-300" }` }
                            onClick={ isMobileValid && isSendOTPClickable ? handleSendOTP : null }
                            style={ isMobileValid && isSendOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Send OTP
                        </button>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ isVerifyOTPClickable ? "bg-green-500" : "bg-green-300" }` }
                            onClick={ isVerifyOTPClickable ? handleVerificationSubmit : null }
                            style={ isVerifyOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Verify
                        </button>*/}

                        <button
                            className={ `lg:px-24 py-2 rounded-[12px] text-white border-2 text-xl font-semibold sm:px-10 ${ isMobileValid && isSendOTPClickable ? "bg-gray-500" : "bg-gray-300" }` }
                            onClick={ isMobileValid && isSendOTPClickable ? handleSendOTP : null }
                            style={ isMobileValid && isSendOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Send OTP
                        </button>
                        <button
                            className={ `lg:px-24 py-2 rounded-[12px] text-white border-2 text-xl font-semibold sm:px-10 ${ isVerifyOTPClickable ? "bg-green-500" : "bg-green-300" }` }
                            onClick={ isVerifyOTPClickable ? handleVerificationSubmit : null }
                            style={ isVerifyOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Verify
                        </button>

                    </>
                </div>
            </div>
        </>
    );
};

export default MobileVerification;


//useEffect( () => {
//    if ( !isLoading ) {
//        if ( data && !error ) {
//            notification.success( {
//                message: 'OTP Verified',
//                description: 'Your OTP has been successfully verified.'
//            } );
//            dispatch( setIsFormSubmitted( true ) );
//            if ( client_name === 'icat' && grade ) {
//                dispatch( setIsFormSubmitted( true ) );
//                navigate( `/${ client_name }/test/${ grade }/thank_you` )
//                localStorage.clear();
//            }
//            localStorage.clear();
//        } else if ( error ) {
//            notification.error( {
//                message: 'OTP Verification Failed',
//                description: 'The OTP you entered is incorrect. Please try again.'
//            } );
//        }
//    }
//}, [data, isLoading, error, client_name, grade, dispatch, navigate] );

//useEffect( () => {
//    if ( !isLoading ) {
//        if ( data && !error ) {
//            notification.success( {
//                message: 'OTP Verified',
//                description: 'Your OTP has been successfully verified.'
//            } );

//            dispatch( setIsFormSubmitted( true ) );

//            if ( client_name === 'icat' && grade ) {
//                dispatch( setIsFormSubmitted( true ) );
//                navigate( `/${ client_name }/test/${ grade }/thank_you` );

//                // Clear all items in localStorage except 'testID'
//                Object.keys( localStorage ).forEach( ( key ) => {
//                    if ( key !== 'userAssessmentResponseID' && key !== 'student_name' && key !== 'student_roll_number' ) {
//                        localStorage.removeItem( key );
//                    }
//                } );
//                localStorage.setItem( 'isFormSubmitted', true )
//            }

//            localStorage.setItem( 'isFormSubmitted', true )

//        } else if ( error ) {
//            notification.error( {
//                message: 'OTP Verification Failed',
//                description: 'The OTP you entered is incorrect. Please try again.'
//            } );
//        }
//    }
//}, [data, isLoading, error, client_name, grade, dispatch, navigate] );